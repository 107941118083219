import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { enableProdMode } from '@angular/core';

import { AppComponent } from './app.component';
import { ProductComponent } from './components/product/product.component';
import { FooterComponent } from './components/footer/footer.component';

import { ProductsPage } from './pages/products-page/products-page.component';
import { SubscriptionsPage } from './pages/subscriptions-page/subscriptions-page.component';

import { PriceFormatPipe } from './pipes/price-format.pipe';

import { ProductService } from './services/product.service';
import { ProviderService } from './services/provider.service';
import { SubscriptionService } from './services/subscription.service';

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    FooterComponent,
    ProductsPage,
    SubscriptionsPage,
    PriceFormatPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: '',
        component: ProductsPage
      },
      {
        path: 'products',
        component: ProductsPage
      },
      {
        path: 'subscriptions',
        component: SubscriptionsPage
      }
    ])
  ],
  providers: [
    ProductService,
    ProviderService,
    SubscriptionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// if (process.env.ENV === 'production') {
//   enableProdMode()
// }
