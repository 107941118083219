import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from '../models/Subscription';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpClient: HttpClient) { }

  getSubscriptions(): Observable<Subscription[]> {
    return this.httpClient.get<Subscription[]>('/api/v1/subscriptions');
  }

  createSubscription(sub: Subscription) {
    return this.httpClient.post('/api/v1/subscriptions', { subscription: sub});
  }

  deleteSubscription(id: number) {
    return this.httpClient.delete(`/api/v1/subscriptions/${id}`);
  }
}
