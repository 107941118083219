import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Provider } from '../models/Provider';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private httpClient: HttpClient) { }

  getProviders(): Observable<Provider[]> {
    return this.httpClient.get<Provider[]>('/api/v1/providers');
  }

  getProvider(id: number): Observable<Provider> {
    return this.httpClient.get<Provider>(`/api/v1/products/${id}`);
  }
}
