import { Component, OnInit } from '@angular/core';
import { Provider } from 'app/app/models/Provider';
import { Subscription } from 'app/app/models/Subscription';
import { SubscriptionService } from 'app/app/services/subscription.service';
import { ProviderService } from 'app/app/services/provider.service';

@Component({
    selector: 'subscriptions-page',
    templateUrl: `app/pages/subscriptions-page/subscriptions-page`
})
export class SubscriptionsPage implements OnInit {

    subscriptions: Subscription[] = [];
    providers: Provider[] = [];
    showPush: boolean = false;
  
    constructor(private subscriptionService: SubscriptionService, private providerService: ProviderService) { }
  
    async ngOnInit(): Promise<void> {
      this.providerService.getProviders()
        .subscribe(res => {
          this.providers = res;
        });
  
      this.subscriptionService.getSubscriptions()
        .subscribe(res => {
          this.subscriptions = res;
        });
  
      // this.showPush = this.isPushAPIAvailable();
    }
  
    // isPushAPIAvailable(): boolean {
    //   if (!('serviceWorker' in navigator)) {
    //     // Service Worker isn't supported on this browser, disable or hide UI.
    //     return false;
    //   }
  
    //   if (!('PushManager' in window)) {
    //     // Push isn't supported on this browser, disable or hide UI.
    //     return false;
    //   }
  
    //   return true;
    // }
  
    isChannelActive(providerId, channel) {
      return this.subscriptions.filter(e => e.provider_id === providerId && e.channel === channel).length > 0;
    }
  
    onChange(event, providerId, channel) {
      if (event.target.checked) {
  
        if (channel === "push") {
          // this.enablePushNotifications();
          return;
        }
  
        this.subscriptionService.createSubscription({
          user_id: 0,
          provider_id: providerId,
          channel: channel
        }).subscribe();
      } else {
        // Delete Sub
        const deleteSub: Subscription = this.subscriptions.filter(e => e.provider_id === providerId && e.channel === channel)[0];
        this.subscriptionService.deleteSubscription(deleteSub.id).subscribe();
      }
    }
  
    // enablePushNotifications() {
    //   const reg = this.registerServiceWorker();
    // }
  
    // registerServiceWorker() {
    //   return navigator.serviceWorker.register('/push-worker.js')
    //     .then((registration) => {
    //       console.log('Service worker successfully registered.');
    //       this.askPermission();
    //     })
    //     .catch(function (err) {
    //       console.error('Unable to register service worker.', err);
    //     });
    // }
  
    // askPermission() {
    //   return new Promise(function (resolve, reject) {
    //     const permissionResult = Notification.requestPermission(function (result) {
    //       resolve(result);
    //     });
  
    //     if (permissionResult) {
    //       permissionResult.then(resolve, reject);
    //     }
    //   })
    //     .then((permissionResult) => {
    //       if (permissionResult !== 'granted') {
    //         console.error('We weren\'t granted permission.');
    //       } else {
    //         console.log(permissionResult);
    //         this.subscribeUserToPush();
    //       }
    //     });
    // }
  
    // subscribeUserToPush() {
    //   return navigator.serviceWorker.register('/push-worker.js')
    //     .then((registration) => {
    //       const subscribeOptions = {
    //         userVisibleOnly: true,
    //         applicationServerKey: this.urlBase64ToUint8Array('BFRCmcRvoAiUlqgw7l1j3oU1s74rY4lIZj49i-Zdi_YtN9_o6m5TTxp9Me9fsXhFPJNicOREVVHCt-L8yPx9lkI')
    //       };
  
    //       return registration.pushManager.subscribe(subscribeOptions);
    //     })
    //     .then(function (pushSubscription) {
    //       console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
    //       console.log(pushSubscription);
  
    //       navigator.serviceWorker.ready
    //         .then((serviceWorkerRegistration) => {
    //           serviceWorkerRegistration.pushManager.getSubscription()
    //             .then((subscription) => {
    //               console.log(subscription);
    //               fetch('/api/v1/users/current/push', {
    //                 method: 'POST',
    //                 headers: {
    //                   'Accept': 'application/json',
    //                   'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(subscription)
    //               });
    //             });
    //           return pushSubscription;
    //         });
    //     });
    // }
  
    // urlBase64ToUint8Array(base64String) {
    //   const padding = '='.repeat((4 - base64String.length % 4) % 4);
    //   const base64 = (base64String + padding)
    //     .replace(/-/g, '+')
    //     .replace(/_/g, '/');
  
    //   const rawData = window.atob(base64);
    //   const outputArray = new Uint8Array(rawData.length);
  
    //   for (let i = 0; i < rawData.length; ++i) {
    //     outputArray[i] = rawData.charCodeAt(i);
    //   }
    //   return outputArray;
    // }
  }
  