import { Component, OnInit } from '@angular/core';
import { Product } from '../../models/Product';
import { Provider } from 'app/app/models/Provider';
import { ProductService } from 'app/app/services/product.service';
import { ProviderService } from 'app/app/services/provider.service';

@Component({
  selector: 'products-page',
  templateUrl: `app/pages/products-page/products-page`
})
export class ProductsPage implements OnInit {

  products: Product[] = [];
  filteredProducts: Product[] = [];
  providers: Provider[] = [];
  filters: any = [];

  constructor(private productService: ProductService, private providerService: ProviderService) { }

  async ngOnInit(): Promise<void> {
    this.productService.getProducts()
    .subscribe(res => {
      this.products = res;
      this.products.sort((x, y) => (x.available === y.available)? 0 : x.available ? -1 : 1);
      this.filteredProducts = JSON.parse(JSON.stringify(this.products));
    });

    this.providerService.getProviders()
    .subscribe(res => {
      this.providers = res;
    });
  }

  onFilter(provider: Provider) {
    if (!this.filters.includes(provider.id)) {
      this.filters.push(provider.id);
    } else {
      this.filters.splice(this.filters.indexOf(provider.id), 1);
    }

    if (this.filters.length !== 0) {
      this.filteredProducts = this.products.filter(e => {
        return this.filters.includes(e.provider.id);
      });
    } else {
      this.filteredProducts = this.products;
    }
  }
}
