import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app',
  templateUrl: 'app/app'
})
export class AppComponent implements OnInit {
  location = window.location.pathname;

  async ngOnInit(): Promise<void> { }
}
