import { Pipe, PipeTransform } from '@angular/core';
/*
 * Format the value to a price label
 * Usage:
 *   value | price
 * Example:
 *   {{ 50 | price }}
 *   formats to: 50.-
*/
@Pipe({name: 'price'})
export class PriceFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value % 1 === 0) {
        return 'CHF ' + value + '.-';
    }

    if (value === 0) {
      return '-';
    }

    return 'CHF ' +  Number(value).toFixed(2);
  }
}
