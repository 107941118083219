import { Component, Input } from '@angular/core';

@Component({
    selector: 'product',
    templateUrl: `app/components/product/product`
})
export class ProductComponent {
    @Input() title: string;
    @Input() imageSrc: string;
    @Input() price: number;
    @Input() priceOriginal: number;
    @Input() amountTotal: string;
    @Input() amountAvailable: string;
    @Input() url: string;
    @Input() providerName: string;
    @Input() providerLogo: string;

    getProgress(): string {
        if (Number(this.amountTotal) && Number(this.amountAvailable)) {
            return Math.round(100 / Number(this.amountTotal) * Number(this.amountAvailable)).toString();
        }

        const patt = /(^[\d]*%$)/;

        if (this.amountAvailable && this.amountAvailable.match(patt)) {
            return this.amountAvailable.replace('%', '');
        }

        return '0';
    }
}
